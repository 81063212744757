import React, { useState, useEffect } from 'react'
import { UserAuth } from '../../context/AuthContext';
import { Row, Alert, Button, Space, Input, message, Modal, Col, Card, theme, Divider, Switch, ConfigProvider, Tag } from 'antd';
import { LogoutOutlined, CloudUploadOutlined, UserDeleteOutlined, WarningFilled, EditOutlined } from "@ant-design/icons";
import { db, dbRef, query, get, remove, orderByChild, equalTo, auth, onValue } from "../../firebase_setup/firebase";
import { deleteUser, EmailAuthProvider, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, TwitterAuthProvider } from 'firebase/auth';
import { AppBar, PremiumCheckout } from "../../components";
import { api, deleteUserFiles } from "../../utils";
import { motion } from "framer-motion";
import styles from "./Account.module.css";

const { useToken } = theme;

export function Account() {

  const { token } = useToken();
  const { user, logOut, authProvider, userData } = UserAuth();

  const [loadingStripe, setLoadingStripe] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [safetyDisable, setSafetyDisable] = useState(true);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  

  const handleSignOut = async () =>{
    try{
      await logOut();
    } catch (err) {
      message.error(err)
    }
  }
  const showDeletePopup = () => {
    setDeletePopupOpen(true);
  };
  const handleCloseDeletePopup = () => {
    setDeletePopupOpen(false);
  };
  const deleteUserData = async () => {
    const dataRef = dbRef(db, "/research_list");
    const userRef = dbRef(db, `/users/${user.uid}`);
    const dataQuery = query(dataRef, orderByChild("uid"), equalTo(user.uid));
    await deleteUserFiles(user);
    await remove(userRef);
    await get(dataQuery)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            remove(dbRef(db, `research_list/${childKey}`)); // Change this line
          });
        } else {
          message.info("No matching data found.");
        }
      })
      .catch((error) => {
        message.error("Error while fetching data:", error);
      });
  };

  const checkSafetyPass = (e) => {
    if(e.target && e.target.value === userData.email){
      setSafetyDisable(false);
    } else {
      setSafetyDisable(true);
    }
  }

  const reauthenticateUser = async () => {
    try {
      switch(authProvider) {
        case 'google.com': {
          const provider = new GoogleAuthProvider();
          await signInWithPopup(auth, provider);
          break;
        }
        case 'twitter.com': {
          const provider = new TwitterAuthProvider();
          await signInWithPopup(auth, provider);
          break;
        }
        case 'password': {
          throw new Error("Email reauthentication not supported. If you need to delete your account please reach out to contact@researchstudio.ai.");
        }
        default:
          console.error(`Unsupported login provider: ${authProvider}`);
          throw new Error(`Unsupported login provider`);
      }
    } catch (error) {
      throw error;
    }
  };
  const handleDelete = async () => {
    try {
      await reauthenticateUser();
      await deleteUserData();
      await deleteUser(user);
      await logOut(); // Ensure logout is called after the user is deleted
      message.success("User account deleted successfully");
    } catch (error) {
      message.error("Error:" + error);
    }
  };
  const handlePortalSession = async ( ) => {
    setLoadingStripe(true);
    try {
      if(userData.customerId){
        const portalResponse = await api.post('/create-portal-session', { customerId: userData.customerId });
        const portalUrl = portalResponse.data.url;
        window.location.href = portalUrl; // Redirect the user to the Stripe Customer Portal
      }
    } catch (err) {
      message.error(err);
    }
  }
  

  const handleCheckoutModalOpen = () => {
    setCheckoutModalOpen(true);
  }
  const handleCheckoutModalClose = () => {
    setCheckoutModalOpen(false);
  }
    
  return (
    <div
      className={styles.profileContainer}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        flexGrow: 1,
      }}
    >
      <AppBar
        title={user.displayName}
        verticalCentering
        avatarSrc={user.photoURL}
      />
      <>
        <Row gutter={16} justify="center">
          <Col xxl={8} xl={10} lg={12} md={16} xs={24}>
            <motion.div initial={{ opacity: 0, scale: 0.7 }} whileInView={{ opacity: 1, scale: 1 }}>
              <Card title="Account Details">
                <p className={styles.infoRow}>
                  <b>Subscription: </b>
                  {userData &&
                    (userData.subscriptionStatus === "Trial" ? (
                      <>
                        { userData.remainingTrial > 0 ? `${userData.remainingTrial} days of trial left` : <p><WarningFilled  style={{ color: token.orange }}/><b> Trial expired</b></p> }
                        <Button onClick={handleCheckoutModalOpen}>
                          <CloudUploadOutlined /> Upgrade Now
                        </Button>
                        <PremiumCheckout
                          modalOpen={checkoutModalOpen}
                          modalCloseMethod={handleCheckoutModalClose}
                        />
                      </>
                    ) : (
                      <>
                        {userData.subscriptionStatus}
                        {userData.customerId && (
                          <Button
                            loading={loadingStripe}
                            onClick={handlePortalSession}
                          >
                            <EditOutlined />
                          </Button>
                        )}
                      </>
                    ))}
                </p>
                <Divider />
                <p className={styles.infoRow}>
                  <b>Name: </b>
                  {user && user.displayName}
                </p>
                <Divider />
                <p className={styles.infoRow}>
                  <b>Email: </b>
                  {userData && userData.email}
                </p>
                <Divider />
                <Row
                  justify="center"
                  align="middle"
                  style={{ marginTop: "16px" }}
                >
                  <Button
                    onClick={handleSignOut}
                    danger
                    type="default"
                    style={{ marginRight: "8px" }}
                  >
                    <LogoutOutlined /> Log Out
                  </Button>
                  <Button onClick={showDeletePopup} danger type="primary">
                    <UserDeleteOutlined /> Delete Account
                  </Button>
                </Row>
              </Card>
            </motion.div>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={24} className="mobileOnly">
            <Card
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "100%",
                marginTop: "16px",
              }}
              title="Helpful Links"
            >
              <a
                href="https://researchstudio.ai/help/getting-started"
                target="_blank"
                rel="noreferrer"
                className={styles.infoRow}
              >
                Getting started
              </a>
              <Divider />
              <a
                href="https://researchstudio.ai/help"
                target="_blank"
                rel="noreferrer"
                className={styles.infoRow}
              >
                Help Center
              </a>
              <Divider />
              <a
                href="https://researchstudio.ai/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className={styles.infoRow}
              >
                Privacy Policy
              </a>
              <Divider />
              <a
                href="https://researchstudio.ai/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className={styles.infoRow}
              >
                Privacy Policy
              </a>
              <Divider />
              <a
                href="https://researchstudio.ai/tos"
                target="_blank"
                rel="noreferrer"
                className={styles.infoRow}
              >
                Terms of Service
              </a>
              <Divider />
              <a
                href="https://researchstudio.ai/refund"
                target="_blank"
                rel="noreferrer"
                className={styles.infoRow}
              >
                Refund Policy
              </a>
            </Card>
          </Col>
        </Row>

        <Modal
          title={
            <>
              <WarningFilled style={{ color: token.colorError }} /> Delete
              Account?
            </>
          }
          open={deletePopupOpen}
          okText="Delete Account"
          onOk={handleDelete}
          okButtonProps={{ danger: true, disabled: safetyDisable }}
          cancelText="Abort"
          onCancel={handleCloseDeletePopup}
        >
          <Space style={{ width: "100%" }} direction="vertical" size="middle">
            {!safetyDisable && (
              <Alert
                type="error"
                message={
                  <p>
                    Are you sure you want to delete your account. You account
                    with all of your data will be <b>permanently</b> deleted!
                  </p>
                }
              />
            )}
            <p>Type in your email address to confirm this action</p>
            <Input onChange={(e) => checkSafetyPass(e)} />
          </Space>
        </Modal>
      </>
    </div>
  );
}
