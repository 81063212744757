import React, { useEffect, useState } from "react";
import {
  Row,
  Space,
  message,
  Input,
  Button,
  Card,
  Divider,
  Result,
} from "antd";
import logo from "../../logo.png";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  auth,
  db,
  get,
  dbRef,
  onAuthStateChanged,
  update,
  set,
} from "../../firebase_setup/firebase";
import { DEFAULT_BRANDING } from "../../utils/consts";
import { api } from "../../utils/api";
import { LoginOutlined, WarningFilled } from "@ant-design/icons";

import styles from "./Login.module.css";

export const Login = () => {
  const { googleSignIn, xSignIn, user, emailSignIn, emailSignUp } = UserAuth();

  const [needsVerification, setNeedsVerification] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);

  const navigate = useNavigate();
  const handleEmailAuth = async () => {
    try {
      let userCredential;

      if (isSignUp) {
        userCredential = await emailSignUp(email, password);
      } else {
        userCredential = await emailSignIn(email, password);
      }
      const user = userCredential;
      if(user.emailVerified === false){
        return;
      }
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // If verified, save to database
          const now = Date.now();
          const userRef = dbRef(db, `users/${user.uid}`);
          const brandingRef = dbRef(db, `users/${user.uid}/branding`);
          const snapshot = await get(userRef);

          if (!snapshot.exists()) {
            // New user logic
            const newUser = {
              email: user.email,
              subscriptionStatus: "Trial",
              branding: DEFAULT_BRANDING,
              remainingTrial: 3,
              lastUpdated: now,
            };
            await set(userRef, newUser);
            await set(brandingRef, DEFAULT_BRANDING);

            // Send welcome email
            await api.post("/api/sendWelcomeEmail", { user });
          } else {
            // Existing user logic
            if (!snapshot.val().lastUpdated) {
              await update(userRef, { lastUpdated: now });
            }
            if (!snapshot.child("branding").exists()) {
              await set(brandingRef, DEFAULT_BRANDING);
            }
          }}
        }
      );
      // Redirect if successful
      navigate("/");
    } catch (error) {
      message.error("Authentication error: " + error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      let emailSent = 0;
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Create a reference to the user in the database
          const userRef = dbRef(db, "users/" + user.uid);
          const brandingRef = dbRef(db, "users/" + user.uid + "/branding");
          const now = Date.now();

          // Fetch the data at this location
          const snapshot = await get(userRef);
          const brandingSnapshot = await get(brandingRef);

          if (!snapshot.exists()) {
            // If the data does not exist, it's a new user
            // Set their subscription status to 'Free'
            const newUser = {
              email: user.email,
              subscriptionStatus: "Trial",
              branding: DEFAULT_BRANDING,
              remainingTrial: 3,
              lastUpdated: now,
            };
            await set(userRef, newUser);
            if (emailSent < 1) {
              await api.post("/api/sendWelcomeEmail", { user });
            }
            emailSent++;
            // Since this is a new user, check the welcomeEmailSent property from the newUser object
          } else {
            // If the user is an 'Open Beta' user, transition them to 'Free' (or another status)
            if (
              snapshot.val().subscriptionStatus === "Open Beta" ||
              snapshot.val().subscriptionStatus === "Free"
            ) {
              await update(userRef, {
                subscriptionStatus: "Trial",
                remainingTrial: 3,
                lastUpdated: now,
              });
            }
            if (!snapshot.val().lastUpdated) {
              await update(userRef, {
                lastUpdated: now,
              });
            }
            if (!brandingSnapshot.exists()) {
              await set(brandingRef, DEFAULT_BRANDING);
            }
          }

          navigate("/");
        }
      });
    } catch (error) {
      message.error(error);
    }
  };

  const handleXLogin = async () => {
    try {
      await xSignIn();
      let emailSent = 0;
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Create a reference to the user in the database
          const userRef = dbRef(db, "users/" + user.uid);
          // Fetch the data at this location
          const now = Date.now();
          const snapshot = await get(userRef);
          const userEmail = user.providerData[0].email;
          const userObject = {
            displayName: user.displayName,
            email: userEmail,
          };
          if (!snapshot.exists() && userEmail) {
            // If the data does not exist, it's a new user
            // Set their subscription status to 'Free'
            const newUser = {
              email: user.email,
              subscriptionStatus: "Trial",
              branding: DEFAULT_BRANDING,
              remainingTrial: 3,
              lastUpdated: now,
            };
            await set(userRef, newUser);
            // Since this is a new user, check the welcomeEmailSent property from the newUser object
            if (emailSent < 1) {
              await api.post("/api/sendWelcomeEmail", { userObject });
            }
            emailSent++;
            // Since this is a new user, check the welcomeEmailSent property from the newUser object
          }
          navigate("/");
        }
      });
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (user && user.uid) {
      navigate("/");
    }
  }, [user]);
  return (
    <Row align="middle" justify="center" className={styles.loginForm}>
      <div className={styles.logoWrapper}>
        <Link to="/" className={styles.logo}>
          <img alt="Research studio logo light version" src={logo} />
        </Link>
        <h2>Next Level UX Research</h2>
      </div>
      <Space align="center" direction="vertical" size="small">
        <Card
          style={{ minWidth: "380px", maxWidth: "100%" }}
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <h1>{isSignUp ? "Sign Up" : "Login"} with Email</h1>
          <Input
            size="large"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            size="large"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p>
            {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
            <Button type="link" onClick={() => setIsSignUp(!isSignUp)}>
              {isSignUp ? "Login" : "Sign Up"}
            </Button>
          </p>
          <button
            disabled={email.length <= 0 || password.length <= 0 ? true : false}
            className={styles.googleLogin}
            onClick={handleEmailAuth}
          >
            <LoginOutlined />
            {isSignUp ? "Sign Up" : "Login"}
          </button>

          <Divider>Or</Divider>
          <Button
            size="large"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 9.61885C18 14.8566 14.4221 18.5451 9.14754 18.5451C4.05738 18.5451 0 14.4877 0 9.39754C0 4.34426 4.05738 0.25 9.14754 0.25C11.582 0.25 13.6844 1.17213 15.2705 2.64754L12.7623 5.04508C9.51639 1.90984 3.46721 4.27049 3.46721 9.39754C3.46721 12.6066 6.0123 15.1885 9.14754 15.1885C12.7623 15.1885 14.127 12.6066 14.3115 11.2418H9.14754V8.10656H17.8525C17.9262 8.58607 18 9.02869 18 9.61885Z"
                  fill="#5e28d1"
                />
              </svg>
            }
            onClick={handleGoogleSignIn}
          >
            Login with Google
          </Button>
          <Button
            size="large"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1273_1885)">
                  <path
                    d="M10.5237 7.77566L16.4811 1H15.0699L9.89493 6.88201L5.7648 1H1L7.24693 9.89549L1 16.9999H2.4112L7.87253 10.787L12.2352 16.9999H17M2.92053 2.04126H5.08853L15.0688 16.0098H12.9003"
                    fill="#5e28d1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1273_1885">
                    <rect
                      width="16"
                      height="16"
                      fill="#5e28d1"
                      transform="translate(1 1)"
                    />
                  </clipPath>
                </defs>
              </svg>
            }
            onClick={handleXLogin}
          >
            Login with X
          </Button>
        </Card>

        <p className={styles.loginDisclaimer} style={{ marginTop: "32px" }}>
          By continuing you agree on our{" "}
          <a
            target="_blank"
            className={styles.loginLink}
            href="https://researchstudio.ai/privacy-policy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and our{" "}
          <a
            target="_blank"
            className={styles.loginLink}
            href="https://researchstudio.ai/tos"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </p>
      </Space>
    </Row>
  );
};
