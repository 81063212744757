import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  DownOutlined,
  PlayCircleFilled,
  HomeOutlined,
  MessageOutlined,
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
  LockFilled,
  DeleteOutlined,
  InfoCircleFilled,
  ExportOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  Col,
  Typography,
  Row,
  Card,
  theme,
  Alert,
  Tag,
  Button,
  Modal,
  Result,
  message,
  notification,
  Dropdown,
  Drawer,
  Space,
  Divider,
} from "antd";

import { db, onValue, dbRef, update, set, remove, get } from "../../firebase_setup/firebase";
import { UserAuth } from '../../context/AuthContext'
import { AppBar, Loader } from "../../components"
import styles from "./ResearchPage.module.css";
import { ResearchReport } from "./ResearchReport";
import { 
  api,
  FREE_RESEARCH_ASSETS_LIMIT, 
  PAID_RESEARCH_ASSETS_LIMIT, 
  viewResearchFiles,
  uploadTextToResearch,
  tokenFetcher,
  updateFileTags,
  rsSummary,
  FolderIcon,
  StatementsCover,
  StatementsIcon,
  PersonaIcon,
  PersonaCover,
  CJourneyIcon,
  CJourneyCover,
  CompetitorsIcon,
  CompetitorsCover,
  QuestionIcon,
  QuestionCover,
  NotionIcon,
  MiroIcon,
  JTBDIcon,
  JTBDCover,
  UserStoriesIcon,
  UserStoriesCover,
} from '../../utils';
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { motion } from "framer-motion";
import { 
  InsightsWidget, 
  CompetiorsWidget,
  QuestionsWidget,
  PersonaWidget, 
  SentimentWidget, 
  ChatWidget, 
  CustomerJourneyWidget,
  JTBDWidget,
  UserStoriesWidget,
  StatementsWidget,
  UploadWidget } from "../../widgets";
// import { Title } from "chart.js";
import { DraggableWidget, DroppableArea, WidgetsPanel } from "./WidgetsPanel";
import { AssetsPanel } from "./AssetsPanel";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { HighlightMenu, MenuButton } from "react-highlight-menu";

const MemoizedMarkdownPreview = React.memo(({ source, style }) => {
    return <MarkdownPreview style={style} source={source} />;
});

const { useToken } = theme;
export function ResearchPage() {

const widgets = [
  {
    id: 'statements', 
    text: 'Statements',
    icon: StatementsIcon,
    cover: StatementsCover,
    description: "Extract user statements with respective sentiments"
  },
  {
    id: 'jtbd', 
    text: "Jobs to be Done",
    icon: JTBDIcon,
    cover: JTBDCover,
    description: "Extract Jobs to be Done from your user's feedback and rank them accordingly"
  },
  {
    id: 'persona', 
    text: 'User Persona',
    icon: PersonaIcon,
    cover: PersonaCover,
    description: "Generate a user persona using your research data"
  },
  {
    id: 'cjourney', 
    text: 'User Journey',
    icon: CJourneyIcon,
    cover: CJourneyCover,
    description: "Generate User/Customer journey map to better understand your user's behaviour"
  },
  {
    id: 'competitors', 
    text: 'Competitors',
    icon: CompetitorsIcon,
    cover: CompetitorsCover,
    description: "Look up your potential competitors"
  },
  {
    id: 'user-stories', 
    text: 'User Stories',
    icon: UserStoriesIcon,
    cover: UserStoriesCover,
    description: "Create user stories based on your research data"
  },
  {
    id: 'questions', 
    text: 'Questions Analysis',
    icon: QuestionIcon,
    cover: QuestionCover,
    description: "Improve on your future researches by analysing your research questions"
  },
]
  const [researchExists, setResearchExists] = useState(true);
  const [assetsPanelOpen, setAssetPanelOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [researchData, setResearchData] = useState(null);
  const [summary, setSummary] = useState();
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [chatOpenState, setChatOpenState] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [widgetRequestForm, setWidgetRequestForm] = useState(false);
  const [draggedOver, setDraggedOver] = useState(false);
  const [canvasMessages, setCanvasMessages] = useState([]);
  const [reseted, setReseted] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [availableWidgets, setAvailableWidgets] = useState(widgets);
  const [activeWidgets, setActiveWidgets] = useState([]);

  const [notificationApi, contextHolder] = notification.useNotification();
  const scrollEnd = React.useRef(null);
  const  navigate = useNavigate();
  const { token } = useToken();
  const { id } = useParams()
  const { user, userData } = UserAuth();
  const location = useLocation();
  
  // Determine the file size and file count limits based on the user's subscription status
  let fileCountLimit = userData.subscriptionStatus === 'Free' ? FREE_RESEARCH_ASSETS_LIMIT : PAID_RESEARCH_ASSETS_LIMIT;
  let fileSizeLimit = userData.subscriptionStatus === 'Free' ? 5000000  : 20971521;
  const { Title } = Typography;
  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const openNotification = ( message, description, icon ) => {
        notificationApi.open({
            message: message,
            description: description,
            icon: icon,
            duration: 3,
        });
  };
  const handleRequestWidgetClose = () => {
    setWidgetRequestForm(false);
  }
  const handleRequestWidgetOpen = () => {
    setWidgetRequestForm(true);
  }
  const handleAssetsPanelClose = () => {
    setAssetPanelOpen(false);
  };
  const handleAssetsPanelOpen = () => {
    setAssetPanelOpen(true);
  };
  const resetResearchData = async () => {
    setReseted(false);
    await getFiles();
    setCanvasMessages([]);
    setActiveWidgets([]);
    setAvailableWidgets(widgets);
    
    try {
      // Reference to the whole research object
      const researchRef = dbRef(db, `research_list/${id}`);

      // Fetch the current state of the research object
      const researchSnapshot = await get(researchRef);
      if (researchSnapshot.exists()) {
        let data = researchSnapshot.val();
        if (data.messages) {
          Object.keys(data.messages).forEach(key => {
            if (data.messages[key].hasOwnProperty('added')) {
              delete data.messages[key].added;
            }
          });
        }
        data.canvas_widgets = null;
        data.summary = null;
        data.sentiment = null;
        data.takeaways = null;
        data.sentiment = null;
        data.statements = null;
        data.c_journey = null;
        data.competitors = null;
        data.user_persona = null;
        data.questions = null;

        setResearchData(data);
        
        // Update the whole research object 
        await set(researchRef, data);

        // Optionally, fetch the updated research data if needed
      } 

      await getResearchData(db, `research_list/${id}`);
    } catch (error) {
      openNotification(
        "Error Regenerating Data",
        "Failed to regenerate data: " + error,
        <ExclamationCircleTwoTone twoToneColor={token.colorError} />
      );
    }
  }
  const handleResetResearch = async () => {
    setReseted(true);
  }
  const scrollToBottom = () => {
    scrollEnd.current?.scrollIntoView({ behavior: "smooth", block: 'end', inline: 'start' });
  };
  // Example function to handle widget removal from canvas
  const removeWidgetFromCanvas = (widgetId) => {
    // Find the widget in the activeWidgets array
    const widgetToRemove = activeWidgets.find(widget => widget.id === widgetId);
    if (widgetToRemove) {
        // Add it back to the availableWidgets array
        setAvailableWidgets(prev => [...prev, widgetToRemove]);
        // Remove it from the activeWidgets array
        setActiveWidgets(prev => prev.filter(widget => widget.id !== widgetId));
      }
  };

  const canvasWidget = (widgetId) => {
    switch (widgetId) {
      case "persona":
        return <PersonaWidget onRemove={() => removeWidgetFromCanvas(widgetId)} editor={isCorrect()} researchId={id} researchData={researchData} user={user} summary={summary} />;
      case "statements":
        return <StatementsWidget onRemove={() => removeWidgetFromCanvas(widgetId)} editor={isCorrect()} user={user} researchId={id} researchData={researchData} summary={summary} />;
      case "cjourney":
        return <CustomerJourneyWidget onRemove={() => removeWidgetFromCanvas(widgetId)} editor={isCorrect()} researchId={id} researchData={researchData} user={user} summary={summary} />;
      case "competitors":
        return <CompetiorsWidget onRemove={() => removeWidgetFromCanvas(widgetId)} editor={isCorrect()} researchId={id} user={user} researchData={researchData} summary={summary} />;
      case "questions":
        return <QuestionsWidget onRemove={() => removeWidgetFromCanvas(widgetId)} editor={isCorrect()} researchId={id} user={user} researchData={researchData} summary={summary} />;
      case "jtbd":
        return <JTBDWidget onRemove={() => removeWidgetFromCanvas(widgetId)} editor={isCorrect()} researchId={id} user={user} researchData={researchData} summary={summary} />;
      case "user-stories":
        return <UserStoriesWidget onRemove={() => removeWidgetFromCanvas(widgetId)} editor={isCorrect()} researchId={id} user={user} researchData={researchData} summary={summary} />;
      default: return null;
    }
  }

  const handleDragStart = ( event ) => {
    setDragging(true);
    setDraggedOver(false);
    setDraggedItem(event.active.id);
  }
  const handleDragOver = (event) => {
    const overId = event.over?.id;
    if(overId === "droppable"){
      setDraggedOver(true);
    } else if (overId === "widgets-panel"){
      setDraggedOver(false);
    } else {
      setDraggedOver(false);
    }
  }
  // Update to handle drag end (if not already implemented)
  const handleDragEnd = (event) => {
    setDragging(false)
    setDraggedOver(false);
    setDraggedItem(null);
    const { active } = event;
    // Logic to determine if the widget was dropped onto the canvas
    // For simplicity, assume it always is
    if (draggedOver === true) {
      const widgetId = active.id;
      const widgetToAdd = availableWidgets.find(widget => widget.id === widgetId);
      if (widgetToAdd) {
        setActiveWidgets(prev => [...prev, widgetToAdd]);
        setAvailableWidgets(prev => prev.filter(widget => widget.id !== widgetId));
      }
    }
  };

  const removeMessageFromCanvas = async (messageId) => {
    try {
      if (messageId) {
        const answersRef = dbRef(db, `research_list/${id}/messages/${messageId}/`);
        // Toggle the 'added' attribute based on its current state
        await update(answersRef, { added: false });
      }
    } catch (error) {
      console.error(`Couldn't add to research: ${error}`); // Changed to console.error for better error handling
    }
  };

  const getFiles = async () => {
    const retryDelay = () => new Promise(resolve => setTimeout(resolve, Math.random() * 1000 + 2000)); // Delay between 2-3 seconds
    while (true) { // Loop indefinitely until we break out when files are ready or an error occurs
      try {
        const filesResponse = await viewResearchFiles(user, id);
        if (filesResponse.length > 0) {
          const filesReady = filesResponse.every(file => file.syncStatus === 'READY');
          const syncError = filesResponse.every(file => file.syncStatus === 'SYNC_ERROR');
          setFileError(syncError);
          const syncErrorRef = dbRef(db, `research_list/${id}/syncError`);
          set(syncErrorRef, syncError);
          if (filesResponse.fileErrorMessage) {
            setFiles([]);
            openNotification(
              "Failed to Retrieve Files",
              filesResponse.fileErrorMessage,
              <ExclamationCircleTwoTone twoToneColor={token.colorError} />
            );
            setUploading(false);
            break; // Exit the loop in case of error
          } else if (filesReady) {
            setFiles(filesResponse);
            setUploading(false);
            setFilesLoaded(true);
            setLoading(false);
            break; // Files are ready, exit the loop
          } else if (syncError) {
            setFiles([]);
            setLoading(false);
            setFilesLoaded(false);
            setUploading(false);
            setFileError(true);
            break; // Exit loop if sync error
          } else {
            setLoading(true);
            setUploading(true);
            setFilesLoaded(false);
            await retryDelay(); // Wait for 2-3 seconds before retrying
          }
        } else {
          setFiles([]);
          setLoading(false);
          setFilesLoaded(false);
          setUploading(false);
          break; // Exit loop if no files returned
        }
      } catch (error) {
        openNotification(
          "Failed to Retrieve Files",
          "Files couldn't be retrieved: " + error,
          <ExclamationCircleTwoTone twoToneColor={token.colorError} />
        );
        setLoading(false);
        setUploading(false);
        break; // Exit loop on exception
      }
    }
  };
  const UpdateNotice = () => {
    return (
      <div className={styles.draggingArea} style={{ zIndex: 44}}>
        <InfoCircleFilled style={{ fontSize: "64px", color: token.colorPrimary, transition: "ease-in .2s" }} />
        <p>You updated files in the research, you must reset it to have it up to date to your context</p>
        <Button type="primary" style={{ marginTop: "16px" }} onClick={() => resetResearchData()}>Refresh Research</Button>
      </div>
    );
  }

  const getResearchData = async ( database, path ) => {    
    const researchRef = dbRef(database, path);
    setSummary();
    const unsubscribe = onValue(researchRef, ( snapshot ) => {
        const data = snapshot.val();
        if( data ){
          setResearchData({ id, ...data });
          setResearchExists(true);
          if(data.summary){
            setSummary(data.summary);
          }
          if(data.canvas_widgets){
            setActiveWidgets(data.canvas_widgets);
          }
          if(data.messages){
            const messages = Object.values(data.messages);
            const addedMessages = messages.filter(message => message.added === true);
            setCanvasMessages(addedMessages);
          }
        } else{
          setResearchData(null);
          setResearchExists(false);
        }
    });
    return () => unsubscribe();
  }
  const saveSummaryToDb = async ( text ) => {
    const summaryRef = dbRef(db, `research_list/${id}/summary`);
    try{
      await set(summaryRef, text);
    } catch (error){
      openNotification(
        "Error Saving Summary",
        "Failed to save summary to db: " + error,
        <ExclamationCircleTwoTone twoToneColor={token.colorError} />
      );
    }
  }
  const saveCanvasWidgets = async () => {
    const canvasWidgetsRef = dbRef(db, `research_list/${id}/canvas_widgets`);
    const activeWidgetsLength = activeWidgets && activeWidgets.length;
    try {
      if(activeWidgetsLength > 0){
        await set(canvasWidgetsRef, activeWidgets);
      } else {
        await remove(canvasWidgetsRef);
      }
    } catch (error) {
      openNotification(
        "Error Saving Canvas",
        "Failed to save the current canvas state",
        <ExclamationCircleTwoTone twoToneColor={token.colorError} />
      );
    }
  };

  const handleSummary = async() => {
    try{
      if (researchData && !researchData.summary && filesLoaded && loading === false) {
        const response = await rsSummary( user, id, researchData.model ? researchData.model: "041" );
        await saveSummaryToDb(response);
        setSummary(response);
      }
    } catch (error){
      openNotification(
        "Error Generating Summary",
        "Failed to generate a summary: " + error,
        <ExclamationCircleTwoTone twoToneColor={token.colorError} />
      );
    }
  }
  const handleChatDrawerOpen = () => {
    setChatOpenState(true);
  }
  const handleChatDrawerClose = () => {
    setChatOpenState(false);
  }
  const goHome = () => {
    navigate("/");
  }
  const viewReport = () => {
    navigate(`/research/${id}/report`);
  }
  const checkForOldFileSystem = async () => {
      try{
        const userId = user.uid;
        const assetsRef = dbRef(db, `/research_list/${id}/assets`);

        let context = "";
        let oldFilesArray;
        
        oldFilesArray = Object.values(researchData.assets)
        oldFilesArray.forEach( (asset) => {
         context += asset.text;
        });
        
        if(user && user.uid){
          await uploadTextToResearch( userId, id, context, id);
          await updateFileTags( 
            user, 
            id,
            {
              researchId: id
            }
          ); 
          await remove(assetsRef);
          openNotification(
            "Migration Suceeded",
            "Migration to new file system was succesfull",
            <CheckCircleTwoTone twoToneColor={token.colorSuccess} />)
          await getFiles();
        }
        
      } catch (error){
        openNotification(
          "File Migration Failed",
          "We failed to migrate your files to the new file system" + error,
          <ExclamationCircleTwoTone twoToneColor={token.colorError} />)
      }
  }
  const isCorrect = () => {
    if(userData){
      if(userData.subscriptionStatus === "Trial" && userData.remainingTrial === 0){
        return false;
      } else if( userData.subscriptionStatus === "Premium" || userData.remainingTrial > 0){
        return true;
      }
    }
  }
  const modelTag = ( model ) => {
    let tag;
    let tagStyle = {
      backgroundColor: token.colorPrimaryBg,
      borderColor: token.colorPrimaryBorder,
      color: token.colorPrimary,
    };
   
    switch(model){
      case "041":
        tag = <Tag style={ tagStyle }>GPT 4o</Tag>;
        break;
      case "014":
        tag = <Tag style={ tagStyle }>Claude 3</Tag>;
        break;
      case "841":
        tag = <Tag style={ tagStyle }>Mistral AI</Tag>;
        break;
      case "114":
        tag = <Tag style={ tagStyle }>Llama 2 70b</Tag>;
        break;
      default:
        tag = <Tag color="default">GPT 3.5 Turbo (Legacy)</Tag>;
        break;
    }
    return tag;
  }
  const addInsightsFromSelection = (selectedText) => {
    try {

      const newInsights = {
        insight: selectedText,
      }
      const updatedInsights = [newInsights, ...researchData.takeaways];
      const takeawaysRef = dbRef(db, `research_list/${id}/takeaways`);
      set(takeawaysRef, updatedInsights);
      message.success("Insight Added");

    } catch (error){
      openNotification(
        "Error Adding Insight",
        "Failed to add insight to research: " + error,
        <ExclamationCircleTwoTone twoToneColor={token.colorError} />
      );
    }
  }
  const addStatementFromSelection = (selectedText) => {
    try{
      const newStatement = {
        statement: selectedText,
        sentiment: "neutral"
      }
      const updatedStatements = [newStatement, ...researchData.statements];
      const statementsRef = dbRef(db, `research_list/${id}/statements`);
      set(statementsRef, updatedStatements);
      message.success("Statement Added");
    } catch (error){
      openNotification(
        "Error Adding Statement",
        "Failed to add statement to research: " + error,
        <ExclamationCircleTwoTone twoToneColor={token.colorError} />
      );
    }
  }
  const handleNotionIntegration = () => {
    window.open("https://api.notion.com/v1/oauth/authorize?client_id=d9eb3f2c-25eb-4488-b967-bd8b9db641b2&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.researchstudio.ai%2Fsettings%2Fnotion-integration", "_self")
  }
  const exportToNotion = async () => {
    setExporting(true);
    if( userData && (userData.notion_db_id || userData.notion_page_id)){
      try{
        const response = await api.post('/api/createNotionPage', {
          researchData,
          messages: canvasMessages,
          user
        });
        openNotification(
          "Export to Notion Succeded",
          <>Notion export is done, check your page here: <a style={{ color: token.colorPrimaryText }} href={response.data.response.url} target="_blank" rel="noreferrer">View Notion Page <ArrowRightOutlined /></a></>,
          <CheckCircleTwoTone twoToneColor={token.colorSuccess} />
        );
        setExporting(false);

      } catch (error){
        openNotification(
          "Error Exporting to Notion",
          "Failed to export research to Notion: " + error,
          <ExclamationCircleTwoTone twoToneColor={token.colorError} />
        );
        setExporting(false);
      }
    } else {
      handleNotionIntegration();
    }
  }
  const menuItems = [
    {
      key: "1",
      label: <Space onClick={exportToNotion}><img width={20} src={NotionIcon} alt="Notion icon" /> Export to Notion</Space>,
    },
    {
      key: "2",
      label: <Space style={{ opacity: 0.5, cursor: "not-allowed"}}><img width={20} src={MiroIcon} alt="Miro icon" /> Export to Miro <Tag>Comming soon</Tag></Space>,
    }
  ]
  useEffect(() => {
    if(researchData && researchData.assets){
      checkForOldFileSystem().then(() =>{
        setLoading(false);
      });
    }
  }, [researchData, user]);

  useEffect(() => {
    // Handle summary based on researchData and files
        handleSummary();
  }, [researchData, filesLoaded, files]);

  useEffect(() => {
    if (user) {
      getResearchData(db, `research_list/${id}`);
      if(researchData && researchData.syncError){
        setFileError(researchData.syncError)
      } else{
        getFiles();
      }
    }
  }, [db, id, user]);

  useEffect(() => {
    if(user && researchData){
      if( researchData.uid === user.uid ){
        return
      } else {
        goHome();
      }
    }
  }, [user, researchData]);
  useEffect(() => {
    document.body.classList.add('researchPage')
    return () => {
      document.body.classList.remove('researchPage')
    }
  }, []);
  useEffect(() => {
    if(researchData){
      saveCanvasWidgets();
      const activeWidgetIds = activeWidgets.map(widget => widget.id);
      const updatedAvailableWidgets = availableWidgets.filter(widget => !activeWidgetIds.includes(widget.id));
      setAvailableWidgets(updatedAvailableWidgets);
    }
 
  }, [activeWidgets, researchData]);
  useEffect(() => {
    const canvasWidgetsRef = dbRef(db, `research_list/${id}/canvas_widgets`);
    const unsubscribe = onValue(canvasWidgetsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTimeout(() => {
          scrollToBottom();
        }, 250);
      }
    });
    return () => unsubscribe(); // Clean up the subscription when the component unmounts
  }, [researchData && researchData.canvasWidget]);

  if (location.pathname.endsWith('/report')) {
    return <ResearchReport canvasMessages={canvasMessages} researchData={researchData} researchId={id} userData={userData} user={user} />;
  } 
  else {
    return (
      loading ? (
        !uploading ? (
        <Row justify="center" align="middle" style={{ height: "100%" }}>
          {
            summary && researchData.sentiment ? (
              <Loader 
              title="Loading Research"
              loading={loading}
              fullscreen />
            ) : (
              researchData && !summary &&
            <Loader 
              title={filesLoaded ? "Analyzing research" : "Loading Research" }
              description={filesLoaded && "This can take a while depending on the content size"}
              loading={loading}
              fullscreen /> )
          }
        </Row> ): (
          <Loader 
            title="Uploading Files"
            description="This may take a while"
            loading={loading}
            fullscreen />
        )
      ):(
        researchExists ? (
        <DndContext sensors={sensors} onDragOver={handleDragOver} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <DroppableArea isOver={draggedOver} visible={dragging} id="droppable" text={ <p>{draggedOver ? "Drop it" : "Drag a widget"}</p>  } />
            <div className={styles.dashboard} style={{ paddingBottom: isCorrect() && "256px"}}>
              <Drawer
                open={chatOpenState}
                destroyOnClose
                width={500}
                mask={false}
                onClose={handleChatDrawerClose}
                className="chatWidgetDrawer">
                  <ChatWidget 
                    editor={isCorrect()} 
                    researchId={id}  
                    summary={summary} 
                    researchData={researchData} 
                    user={user} 
                    userData={userData} />
              </Drawer>
              {contextHolder}
              <AppBar
                title={ researchData && researchData.name }
                //tag={ researchData && modelTag(researchData.model)}
                description={researchData && researchData.description}
                backButton={true}
                verticalCentering
                toggledOffText="Widgets"
                toggledOnText={ <i style={{color: token.colorTextSecondary }}>Drag and drop widgets on the canvas to start analyzing your research</i> }
                backActionTitle="Back to Researches"
                backDestination="/"
                avatar={
                  <img src={FolderIcon} alt="Folder icon" style={{width: "38px",  marginRight: '16px', marginBottom: "8px" }} />
                }
                cta={
                  summary && <Button className="mobileOnly" type="primary" onClick={handleChatDrawerOpen}><MessageOutlined /> Chat</Button>
                }
                action={
                  filesLoaded && (
                        <Space>
                          <Dropdown
                            menu={{ items: menuItems }}
                            trigger={['click']}>
                            <Button loading={exporting} className="desktopOnly" type="default">
                               Export
                               <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Button className="desktopOnly" type="primary" onClick={viewReport}>
                            <PlayCircleFilled /> Play Presentation
                          </Button>
                        </Space>
                  )
                } 
              />
              { filesLoaded && files.length > 0 ? (
                    <Row gutter={16}>
                      <Col className="desktopOnly" span={24}>
                        <HighlightMenu
                          target=".fileText"
                          allowedPlacements={["top", "bottom"]}
                          menu={({ selectedText = "", setMenuOpen }) => (
                          <>
                              <MenuButton
                                  children={<p>+ Add to Insights</p>}
                                  style={{ background: "transparent", color: token.colorPrimary }}
                                  onClick={() => {
                                      addInsightsFromSelection(selectedText)
                                  }}
                              />
                              {researchData.statements && (

                              <MenuButton
                                  children={<p>+ Add to Statements</p>}
                                  style={{ background: "transparent", color: token.colorPrimary }}
                                  onClick={() => {
                                    addStatementFromSelection(selectedText);
                                  }}
                              />
                              )}
                              <MenuButton
                                  style={{ background: "transparent", color: token.colorError }}
                                  onClick={() => setMenuOpen(false)}
                                  icon="x-mark"
                              />
                          </>
                          )}
                      />
                        <AssetsPanel
                            researchAssets={files}
                            onUpdate={handleResetResearch}
                            onClose={handleAssetsPanelClose}
                            open={assetsPanelOpen}
                            researchId={id}
                            user={user}
                            userData={userData}
                            maxFileSize={fileSizeLimit}
                            maxFilesCount={fileCountLimit}
                            tokenFetcher={() => tokenFetcher(user.uid)}
                            isCorrect={isCorrect}
                            
                            onSuccess={(data) => {
                              if(data){
                                getFiles();
                              }
                            }}
                            onError={(error) => {
                              setFilesLoaded(false);
                              setLoading(false);
                              openNotification("Error Uploading File", "Failed to upload file: " + error)
                            }}
                        />
                      <Divider style={{ marginTop: 48 }} />
                      </Col>
                      <Col span={24}>
                      <motion.div
                        initial={{ transform: "scale(0.7)", opacity: 0 }}
                        whileInView={{ transform: "scale(1)", opacity: 1 }}>
                        <Row gutter={16}>
                            <Col span={24}>
                              <Title level={3}>Overview</Title>
                            </Col>
                            <Col xxl={6} xl={8} lg={10} md={11} xs={24} className="sentimentWidgetCol">
                              <SentimentWidget summary={summary} researchId={id} user={user} researchData={researchData} />
                            </Col>
                            <Col xxl={18} xl={16} lg={14} md={13} xs={24} className="chatWidgetCol">
                              <Card title="Summary">
                                { summary && summary.length > 0 ? <p style={{ whiteSpace: "break-spaces"}}>{summary}</p> : <Loader title="Generating Summary" loading={true} size="small" /> }
                              </Card>
                              { /* <ChatWidget editor={isCorrect()} researchId={id} summary={summary} researchData={researchData} user={user} userData={userData} /> */}
                            </Col>
                        </Row>
                      </motion.div>
                      <motion.div
                        initial={{ transform: "scale(0.7)", opacity: 0 }}
                        whileInView={{ transform: "scale(1)", opacity: 1 }}>
                        <Title level={3}>Insights</Title>
                        <InsightsWidget editor={isCorrect()} user={user} researchId={id} researchData={researchData} summary={summary}/>
                      </motion.div>
                      { researchData.summary && (
                              activeWidgets.map((widget, index) => {
                              return (
                                <motion.div
                                  key={index}
                                  initial={{ scale: 0, opacity: 0 }}
                                  whileInView={{ scale: 1, opacity: 1, transition: { duration: 0.5 }}}
                                  exit={{ scale: 0, opacity: 0 }}>
                                  {canvasWidget(widget.id)}
                                </motion.div>
                              )
                            })
                      )}
                      <div style={{ height: "1px" }} ref={scrollEnd}></div>
                      { researchData.messages && canvasMessages.length > 0 && (
                        <>
                          <Title level={3}>AI Responses</Title>
                          { canvasMessages.map((message, index) => {
                            return (
                              <motion.div
                                key={index}
                                initial={{ scale: 0, opacity: 0 }}
                                whileInView={{ scale: 1, opacity: 1, transition: { duration: 0.5 }}}
                                exit={{ scale: 0, opacity: 0 }}>
                                <Card 
                                  style={{ marginBottom: 16}}
                                  title={
                                    message.external ? 
                                    <Tag color="purple">Context + Broad Knowledge</Tag> : 
                                    <Tag color="orange">Context Only</Tag>}
                                  extra={ 
                                    isCorrect() && 
                                    <Button 
                                      type="link" 
                                      danger 
                                      onClick={() => removeMessageFromCanvas(message.id)}>
                                        <DeleteOutlined color={token.colorError} /></Button>}>
                                  <MemoizedMarkdownPreview 
                                    style={{
                                      backgroundColor: "transparent",
                                      fontFamily: "inherit",
                                      fontSize: 14,
                                      color: "inherit",
                                    }} 
                                    source={message.text} />
                                </Card>
                              </motion.div>
                            )
                          })}
                        </>
                      )}
                      </Col>
                      
                        { reseted && <UpdateNotice /> }
                        {
                          draggedItem === "persona" && (<DragOverlay><DraggableWidget isDragging={dragging} id="persona" text="User Persona" iconSrc={PersonaIcon}  disablePopover /></DragOverlay>)
                        }
                        {
                          draggedItem === "statements" && (<DragOverlay><DraggableWidget isDragging={dragging} id="statements" text="Statements" iconSrc={StatementsIcon}  disablePopover /></DragOverlay>)
                        }
                        {
                          draggedItem === "cjourney" && (<DragOverlay><DraggableWidget isDragging={dragging} id="cjourney" text="User Journey" iconSrc={CJourneyIcon}  disablePopover /></DragOverlay>)
                        }
                        {
                          draggedItem === "competitors" && (<DragOverlay><DraggableWidget isDragging={dragging} id="competitors" text="Competitors" iconSrc={CompetitorsIcon}  disablePopover /></DragOverlay>)
                        }
                        {
                          draggedItem === "questions" && (<DragOverlay><DraggableWidget isDragging={dragging} id="questions" text="Questions Analysis" iconSrc={QuestionIcon}  disablePopover /></DragOverlay>)
                        }
                        {
                          draggedItem === "jtbd" && (<DragOverlay><DraggableWidget isDragging={dragging} id="jtbd" text="Jobs to be Done" iconSrc={JTBDIcon}  disablePopover /></DragOverlay>)
                        }
                        {
                          draggedItem === "user-stories" && (<DragOverlay><DraggableWidget isDragging={dragging} id="user-stories" text="User Stories" iconSrc={UserStoriesIcon}  disablePopover /></DragOverlay>)
                        }
                        {
                          isCorrect() && (
                            <WidgetsPanel
                              openRequestForm={handleRequestWidgetOpen}
                              onChatOpen={handleChatDrawerOpen}
                              widgets={
                                availableWidgets.map(widget => (
                                  draggedItem === widget.id ? (null) : (
                                  <DraggableWidget 
                                    key={widget.id} 
                                    id={widget.id}
                                    text={widget.text}
                                    iconSrc={widget.icon}
                                    coverSrc={widget.cover}
                                    description={widget.description}/> )
                                ))
                              } />
                        )}
                      
                        <Modal footer="" open={widgetRequestForm}  onCancel={handleRequestWidgetClose}>
                          <Alert style={{ marginTop: "24px" }} description="We consider widget requests based on our roadmap and product direction. Thank you for your understanding!" type="info" showIcon />
                          <iframe src="https://tally.so/r/wLWWO2" style={{ width: "100%", height: "500px"}} />
                        </Modal>
                      
                  </Row>
                ) : (
                <Card>
                  { isCorrect() ? (
                    fileError ? (
                      <Result
                        style={{ maxWidth: "600px", margin: "auto" }}
                        status="error"
                        title="File syncing error"
                        subTitle="There was an error syncing your files. Either large or corrupted content. If you are trying to upload a big file, maybe try breaking it down into smaller files and upload them separately."/>
                    ) :
                    <UploadWidget 
                      researchId={id}
                      userData={userData}
                      maxFileSize={fileSizeLimit}
                      maxFilesCount={fileCountLimit}
                      tokenFetcher={() => tokenFetcher(user.uid)}
                      onSuccess={(data) => {
                        if(data){
                          getFiles();
                        }
                      }}
                      onError={(error) => {
                        setFilesLoaded(false);
                        setLoading(false);
                        openNotification("Error Uploading File", "Failed to upload file: " + error)
                      }}
                    />
                  ) : (
                    <Result icon={<LockFilled style={{color: token.orange}} />} subTitle="You need to upgrade to our Premium tier in order to upload your files" title="Upgrade to Premium" />
                  )}
                </Card>
              )} 
          </div>
      </DndContext>) : (
      <Row style={{height: "100%"}} justify="center" align="middle">
        <Result 
          title="Research Doesn't Exist"
          status="404"
          subTitle="This research was not found on our systems"
          extra={ <Button type="primary" onClick={goHome}><HomeOutlined/> Go Home</Button> }
        />
      </Row> ))
    );
  }
}
