import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Input,
  Card,
  Row,
  Col,
  theme,
  Popconfirm,
  Badge,
  Space,
  message,
  Empty,
  Tooltip,
  Button,
  notification,
  Typography,
  Switch,
  Tag,
  Segmented
} from "antd";
import { DeleteOutlined, DeleteTwoTone, CalendarOutlined, FolderAddOutlined, ExclamationCircleTwoTone,  InfoCircleFilled, WarningTwoTone, LockFilled } from "@ant-design/icons";
import { UserAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  db,
  dbRef,
  orderByChild,
  equalTo,
  query,
  onValue,
  remove
} from "../../firebase_setup/firebase";
import { AppBar, Loader } from "../../components";
import { api, FREE_RESEARCH_LIMIT, deleteResearchFiles, MistralIcon, GPTIcon, ClaudeIcon } from "../../utils";
import { motion } from "framer-motion";
import { PremiumCheckout } from "../../components";
import styles from "./ResearchList.module.css"

const { useToken } = theme;
const { TextArea } = Input;
const { Title, Text } = Typography;

export function ResearchList() {
    const defaultModel = "014";

    const { user, userData } = UserAuth();
    const navigate = useNavigate();
    const { token } = useToken();
    const titleInputRef = useRef(null);

    const [addModalOpen, setAddModalOpen] = useState("");
    const [researchName, setResearchName] = useState();
    const [researchModel, setResearchModel] = useState(defaultModel);
    const [researchDescription, setResearchDescription] = useState();
    const [formError, setFormError] = useState(true);
    const [researchList, setResearchList] = useState([]);
    const [descriptionEnabled, setDescriptionEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(true);
    const [premiumCheckoutModalOpen, setPremiumCheckoutModalOpen] = useState(false);
    const [notificationApi, contextHolder] = notification.useNotification();

    const openNotification = ( message, description, icon ) => {
        notificationApi.open({
            message: message,
            description: description,
            icon: icon,
            duration: 3,
        });
    };
    
    async function handleCreateResearch(researchName, researchDescription) {
        try {
            setLoading(true);
            const response = await api.post('/api/create_research', {
                researchName,
                researchDescription,
                userId: user.uid,
                model: researchModel,
                user
            });
            setLoading(false);
            navigate('/research/' + response.data.researchId);
        } catch(error){
            setLoading(false);
            openNotification(
                "Failed to Create Research!", 
                `We couldn't create a research: ${error}`,
                <ExclamationCircleTwoTone twoToneColor={token.colorError} />
            )
        }
    }
    async function deleteResearch(researchId) {
        const researchRef = dbRef(db, `research_list/${researchId}`);
        await deleteResearchFiles( user, researchId )
        await remove(researchRef);
        openNotification(
            "Research Deleted!", 
            "The research and it's files have been deleted sucessfully",
            <DeleteTwoTone twoToneColor={token.colorError} />
        )
    }
    async function getResearchList(database, path) {
        const researchDB = dbRef(database, path);
        const researchQuery = query(
            researchDB,
            orderByChild("uid"),
            equalTo(user.uid)
        );
    
        onValue(researchQuery, (snapshot) => {
            const researchData = snapshot.val();
            let researches = researchData !== null && Object.entries(researchData).map(([id, research]) => ({
                id,
                ...research,
            }));
            if(researches){ researches = researches.sort((a, b) => b.createdAt - a.createdAt)};
            setResearchList(researches);
        });
    }

    const preventAction = (e) => {
        e.preventDefault();
    }
    const showAddModal = () => {
        if(user && userData){
            if( userData.subscriptionStatus === "Free" && researchList.length >= FREE_RESEARCH_LIMIT){
                setAddModalOpen(false);;
            } else {
                setAddModalOpen(true);
            }
        }
        else {
           setAddModalOpen(false);
        }
    };
    const focusTitleInput = () => {
        titleInputRef.current.focus();
    }
    const handleAddModalCancel = () => {
        setAddModalOpen(false);
        setResearchName('');
        setResearchModel(defaultModel);
        setResearchDescription('');
    };
    const validateForm = () => {
        if( descriptionEnabled ){
            if( !researchName || !researchDescription  ){
                setFormError(true);
            }
            else {
                setFormError(false);
            }
        } else  {
            if( !researchName ){
                setFormError(true);
            }
            else {
                setFormError(false);
            }
        }
    }
    const handleAddModalOK = async () => {
        
            if ( !formError ) {
                const createResearch = await handleCreateResearch(researchName, researchDescription);
                const hideModal = setAddModalOpen(false);
                const resetName = setResearchName('')
                const resetDescription = setResearchDescription('')
                return {
                    createResearch,
                    hideModal,
                    resetName,
                    resetDescription
                };
            } else {
                return (message("Please fill in the details"));
            }
        
    };
    const creationDate = ( research ) => {
        const dateOptions = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };
        const date = research && new Date(parseInt(research.createdAt));
        return !isNaN(date) && date.toLocaleString('en-US', dateOptions )
    }
    const toggleDescription = () => {
        if(descriptionEnabled){
            setDescriptionEnabled(false);
        } else {
            setDescriptionEnabled(true);
        }
    }
    const handlePremiumCheckoutOpen = () => {
        setPremiumCheckoutModalOpen(true);
    }
    const handlePremiumCheckoutClose = () => {
        setPremiumCheckoutModalOpen(false);
    }
    const researchModelChange = ( value ) => {
        if(userData.subscriptionStatus === "Trial" && value !== "014"){
            handlePremiumCheckoutOpen();
        } else {
            setResearchModel(value);
        }
    };
    const modelOptiopns = [
        {
            label: (
                <div style={{ padding: "16px 8px 8px 8px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img style={{ height: 24 }} alt="Claude 3 logo" src={ClaudeIcon} />
                    <div>Claude 3</div>
                </div>
            ),
            value: "014"
        },
        {
            label: (
                <div style={{ padding: "16px 8px 8px 8px", opacity: userData.subscriptionStatus === "Trial" && 0.7, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img style={{ height: 24 }} alt="GPT 4o logo" src={GPTIcon} />
                    <div>GPT 4o</div>
                </div>
            ),
            value: "041"
        },
        {
            label: (
                <div style={{ padding: "16px 8px 8px 8px", display: "flex", opacity: userData.subscriptionStatus === "Trial" && 0.7, flexDirection: "column", alignItems: "center" }}>
                    <img style={{ height: 24 }} alt="Mistral AI logo" src={MistralIcon} />
                    <div>Mistral AI</div>
                </div>
            ),
            value: "841"
        },
    ]
    const researchCard = (research) => {
        let card;
        if(research.syncError){
            card = (
                <Badge.Ribbon
                    text="Sync Error"
                    color="red">
                    <Card
                        hoverable
                        className={styles.researchCard}
                        actions={[
                            <Popconfirm
                            title="Delete Research?"
                            okText="Delete Research"
                            onConfirm={() => deleteResearch(research.id)}
                            description="Are you sure you want to delete this research"
                            >
                            <Button type="text" onClick={(e) => preventAction(e)} danger>
                                <DeleteOutlined /> Delete
                            </Button>
                            </Popconfirm>,
                        ]}
                    >
                        <Link
                            className={styles.researchCardLink}
                            to={`/research/${research.id}`}
                        >
                            <Row justify="center" style={{ paddingTop: 12 }} align="middle" wrap={true}>
                            <div className={styles.researchCardContent}>
                                <Text className={styles.researchCardTimestamp}>
                                <CalendarOutlined /> {creationDate(research)}
                                </Text>
                                <svg
                                width="82"
                                height="72"
                                viewBox="0 0 82 72"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <g clipPath="url(#clip0_775_196)">
                                    <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M33.3125 0L43.5625 10.2857H74.3125C78.4766 10.2857 82 13.8214 82 18V64.2857C82 68.625 78.4766 72 74.3125 72H7.6875C3.36328 72 0 68.625 0 64.2857V7.71429C0 3.53571 3.36328 0 7.6875 0H33.3125ZM7.6875 4H31.6516L41.9016 14.2857H74.3125C76.2543 14.2857 78 16.0174 78 18V64.2857C78 66.3748 76.3087 68 74.3125 68H7.6875C5.58553 68 4 66.429 4 64.2857V7.71429C4 5.67718 5.6395 4 7.6875 4ZM33.0294 34.8766C31.5444 35.7168 31.0216 37.6018 31.8618 39.0868C32.702 40.5718 34.5869 41.0945 36.0719 40.2543C37.3098 39.554 38.8816 40.0117 39.55 41.2671L39.7897 41.7174C40.561 43.1661 40.037 44.9655 38.6085 45.7737L28.5686 51.454C27.0836 52.2942 26.5609 54.1791 27.4011 55.6641C28.2412 57.1491 30.1262 57.6719 31.6112 56.8317L46.947 48.1551C48.432 47.3149 48.9548 45.43 48.1146 43.945C47.8179 43.4206 47.3909 43.0162 46.8972 42.7494C46.4692 42.5181 45.9897 42.3194 45.5047 42.1185C44.297 41.6182 43.0548 41.1036 42.4854 40.0341C41.7095 38.5767 42.2367 36.7665 43.6736 35.9535L53.4314 30.4328C54.9164 29.5926 55.4392 27.7077 54.599 26.2227C53.7588 24.7377 51.8739 24.2149 50.3889 25.0551L33.0294 34.8766Z"
                                    fill="url(#paint0_radial_775_196)"
                                    />
                                    <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M33.3125 0L43.5625 10.2857H74.3125C78.4766 10.2857 82 13.8214 82 18V64.2857C82 68.625 78.4766 72 74.3125 72H7.6875C3.36328 72 0 68.625 0 64.2857V7.71429C0 3.53571 3.36328 0 7.6875 0H33.3125ZM7.6875 4H31.6516L41.9016 14.2857H74.3125C76.2543 14.2857 78 16.0174 78 18V64.2857C78 66.3748 76.3087 68 74.3125 68H7.6875C5.58553 68 4 66.429 4 64.2857V7.71429C4 5.67718 5.6395 4 7.6875 4ZM33.0294 34.8766C31.5444 35.7168 31.0216 37.6018 31.8618 39.0868C32.702 40.5718 34.5869 41.0945 36.0719 40.2543C37.3098 39.554 38.8816 40.0117 39.55 41.2671L39.7897 41.7174C40.561 43.1661 40.037 44.9655 38.6085 45.7737L28.5686 51.454C27.0836 52.2942 26.5609 54.1791 27.4011 55.6641C28.2412 57.1491 30.1262 57.6719 31.6112 56.8317L46.947 48.1551C48.432 47.3149 48.9548 45.43 48.1146 43.945C47.8179 43.4206 47.3909 43.0162 46.8972 42.7494C46.4692 42.5181 45.9897 42.3194 45.5047 42.1185C44.297 41.6182 43.0548 41.1036 42.4854 40.0341C41.7095 38.5767 42.2367 36.7665 43.6736 35.9535L53.4314 30.4328C54.9164 29.5926 55.4392 27.7077 54.599 26.2227C53.7588 24.7377 51.8739 24.2149 50.3889 25.0551L33.0294 34.8766Z"
                                    fill="url(#paint1_radial_775_196)"
                                    />
                                </g>
                                <defs>
                                    <radialGradient
                                    id="paint0_radial_775_196"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(22.0416 3.80935) rotate(56.1963) scale(82.0636 65.423)"
                                    >
                                    <stop stopColor="#FFB1EE" />
                                    <stop offset="0.260417" stopColor="#B77EFF" />
                                    <stop offset="0.692708" stopColor="#5056DD" />
                                    <stop offset="1" stopColor="#4F1CDF" />
                                    </radialGradient>
                                    <radialGradient
                                    id="paint1_radial_775_196"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(22.0416 3.80935) rotate(56.1963) scale(82.0636 65.423)"
                                    >
                                    <stop stopColor="#FFB1EE" />
                                    <stop offset="0.260417" stopColor="#B77EFF" />
                                    <stop offset="0.692708" stopColor="#5056DD" />
                                    <stop offset="1" stopColor="#4F1CDF" />
                                    </radialGradient>
                                    <clipPath id="clip0_775_196">
                                    <rect width="82" height="72" fill="white" />
                                    </clipPath>
                                </defs>
                                </svg>
                                <div className={styles.researchCardTitleBox}>
                                <Title level={5} ellipsis={{ tooltip: research.name }}>
                                    {research.name}
                                </Title>
                                {research.description && (
                                    <Text ellipsis={{ tooltip: research.description }}>
                                    {" "}
                                    {research.description}{" "}
                                    </Text>
                                )}
                                </div>
                            </div>
                        </Row>
                    </Link>
                </Card> 
            </Badge.Ribbon> );
        } else {
            card = (
                <Card
                    hoverable
                    className={styles.researchCard}
                    actions={[
                        <Popconfirm
                        title="Delete Research?"
                        okText="Delete Research"
                        onConfirm={() => deleteResearch(research.id)}
                        description="Are you sure you want to delete this research"
                        >
                        <Button type="text" onClick={(e) => preventAction(e)} danger>
                            <DeleteOutlined /> Delete
                        </Button>
                        </Popconfirm>,
                    ]}
                >
                    <Link
                        className={styles.researchCardLink}
                        to={`/research/${research.id}`}
                    >
                        <Row justify="center" align="middle" wrap={true}>
                        { /*modelTag(research.model) */ }
                        <div className={styles.researchCardContent}>
                            <Text className={styles.researchCardTimestamp}>
                            <CalendarOutlined /> {creationDate(research)}
                            </Text>
                            <svg
                            width="82"
                            height="72"
                            viewBox="0 0 82 72"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <g clipPath="url(#clip0_775_196)">
                                <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M33.3125 0L43.5625 10.2857H74.3125C78.4766 10.2857 82 13.8214 82 18V64.2857C82 68.625 78.4766 72 74.3125 72H7.6875C3.36328 72 0 68.625 0 64.2857V7.71429C0 3.53571 3.36328 0 7.6875 0H33.3125ZM7.6875 4H31.6516L41.9016 14.2857H74.3125C76.2543 14.2857 78 16.0174 78 18V64.2857C78 66.3748 76.3087 68 74.3125 68H7.6875C5.58553 68 4 66.429 4 64.2857V7.71429C4 5.67718 5.6395 4 7.6875 4ZM33.0294 34.8766C31.5444 35.7168 31.0216 37.6018 31.8618 39.0868C32.702 40.5718 34.5869 41.0945 36.0719 40.2543C37.3098 39.554 38.8816 40.0117 39.55 41.2671L39.7897 41.7174C40.561 43.1661 40.037 44.9655 38.6085 45.7737L28.5686 51.454C27.0836 52.2942 26.5609 54.1791 27.4011 55.6641C28.2412 57.1491 30.1262 57.6719 31.6112 56.8317L46.947 48.1551C48.432 47.3149 48.9548 45.43 48.1146 43.945C47.8179 43.4206 47.3909 43.0162 46.8972 42.7494C46.4692 42.5181 45.9897 42.3194 45.5047 42.1185C44.297 41.6182 43.0548 41.1036 42.4854 40.0341C41.7095 38.5767 42.2367 36.7665 43.6736 35.9535L53.4314 30.4328C54.9164 29.5926 55.4392 27.7077 54.599 26.2227C53.7588 24.7377 51.8739 24.2149 50.3889 25.0551L33.0294 34.8766Z"
                                fill="url(#paint0_radial_775_196)"
                                />
                                <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M33.3125 0L43.5625 10.2857H74.3125C78.4766 10.2857 82 13.8214 82 18V64.2857C82 68.625 78.4766 72 74.3125 72H7.6875C3.36328 72 0 68.625 0 64.2857V7.71429C0 3.53571 3.36328 0 7.6875 0H33.3125ZM7.6875 4H31.6516L41.9016 14.2857H74.3125C76.2543 14.2857 78 16.0174 78 18V64.2857C78 66.3748 76.3087 68 74.3125 68H7.6875C5.58553 68 4 66.429 4 64.2857V7.71429C4 5.67718 5.6395 4 7.6875 4ZM33.0294 34.8766C31.5444 35.7168 31.0216 37.6018 31.8618 39.0868C32.702 40.5718 34.5869 41.0945 36.0719 40.2543C37.3098 39.554 38.8816 40.0117 39.55 41.2671L39.7897 41.7174C40.561 43.1661 40.037 44.9655 38.6085 45.7737L28.5686 51.454C27.0836 52.2942 26.5609 54.1791 27.4011 55.6641C28.2412 57.1491 30.1262 57.6719 31.6112 56.8317L46.947 48.1551C48.432 47.3149 48.9548 45.43 48.1146 43.945C47.8179 43.4206 47.3909 43.0162 46.8972 42.7494C46.4692 42.5181 45.9897 42.3194 45.5047 42.1185C44.297 41.6182 43.0548 41.1036 42.4854 40.0341C41.7095 38.5767 42.2367 36.7665 43.6736 35.9535L53.4314 30.4328C54.9164 29.5926 55.4392 27.7077 54.599 26.2227C53.7588 24.7377 51.8739 24.2149 50.3889 25.0551L33.0294 34.8766Z"
                                fill="url(#paint1_radial_775_196)"
                                />
                            </g>
                            <defs>
                                <radialGradient
                                id="paint0_radial_775_196"
                                cx="0"
                                cy="0"
                                r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(22.0416 3.80935) rotate(56.1963) scale(82.0636 65.423)"
                                >
                                <stop stopColor="#FFB1EE" />
                                <stop offset="0.260417" stopColor="#B77EFF" />
                                <stop offset="0.692708" stopColor="#5056DD" />
                                <stop offset="1" stopColor="#4F1CDF" />
                                </radialGradient>
                                <radialGradient
                                id="paint1_radial_775_196"
                                cx="0"
                                cy="0"
                                r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(22.0416 3.80935) rotate(56.1963) scale(82.0636 65.423)"
                                >
                                <stop stopColor="#FFB1EE" />
                                <stop offset="0.260417" stopColor="#B77EFF" />
                                <stop offset="0.692708" stopColor="#5056DD" />
                                <stop offset="1" stopColor="#4F1CDF" />
                                </radialGradient>
                                <clipPath id="clip0_775_196">
                                <rect width="82" height="72" fill="white" />
                                </clipPath>
                            </defs>
                            </svg>
                            <div className={styles.researchCardTitleBox}>
                            <Title level={5} ellipsis={{ tooltip: research.name }}>
                                {research.name}
                            </Title>
                            {research.description && (
                                <Text ellipsis={{ tooltip: research.description }}>
                                {" "}
                                {research.description}{" "}
                                </Text>
                            )}
                            </div>
                        </div>
                    </Row>
                </Link>
            </Card> );
        }
        return card;
    };
    const modelTag = ( model ) => {
        let tag;
        let tagStyle = {
            backgroundColor: token.colorPrimaryBg,
            borderColor: token.colorPrimaryBorder,
            color: token.colorPrimary,
            marginBottom: "8px",
            marginRight: 0
        };
        switch(model){
        case "041":
            tag = <Tag style={ tagStyle }>GPT 4o</Tag>;
            break;
        case "014":
            tag = <Tag style={ tagStyle }>Claude 3</Tag>;
            break;
        case "841":
            tag = <Tag style={ tagStyle }>Mistral AI</Tag>;
            break;
        case "114":
            tag = <Tag style={ tagStyle }>Llama 2 70b</Tag>;
            break;
        default:
            tag = <Tag style={ tagStyle }>GPT 4o</Tag>;
            break;
        }
        if(!model){
            tag = <Tag color="default" style={{ marginBottom: "8px", marginRight: 0 }}>GPT 3.5 Turbo (Legacy)</Tag>;
        }
        return tag;
    }
    const researchNameFieldChange = (e) =>  {setResearchName(e.target.value)};
    useEffect(() => {
        validateForm();
    }, [researchName, researchDescription, descriptionEnabled])
    useEffect(() => {
        !descriptionEnabled && setResearchDescription('')
    }, [descriptionEnabled]);
    useEffect(() => {
        if (user){
            setLoadingList(true);
            getResearchList(db, '/research_list');
            setLoadingList(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        loadingList ? <Loader loading={loadingList} title="Fetching Researches..." fullscreen />:

        <div style={{ display: "flex", flexDirection: "column", gap: "24px", flexGrow: 1, paddingTop: "16px" }}>
            {contextHolder}
            <AppBar 
                fullWidthContent
                verticalCentering
                title="Researches"
                cta= { userData && researchList && ((userData.subscriptionStatus === "Trial" && userData.remainingTrial > 0) || userData.subscriptionStatus === "Premium") && <Button type="primary" onClick={ showAddModal }><FolderAddOutlined /> Create Research </Button> }/>
                    <Row gutter={16} align="stretch" wrap={true}>
                    {   !researchList && 
                        <Row justify="center" style={{width:"100%"}} align="middle">
                            <Empty 
                                description="No Research Created" 
                                image={
                                    <svg width="82" height="72" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_775_196)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M33.3125 0L43.5625 10.2857H74.3125C78.4766 10.2857 82 13.8214 82 18V64.2857C82 68.625 78.4766 72 74.3125 72H7.6875C3.36328 72 0 68.625 0 64.2857V7.71429C0 3.53571 3.36328 0 7.6875 0H33.3125ZM7.6875 4H31.6516L41.9016 14.2857H74.3125C76.2543 14.2857 78 16.0174 78 18V64.2857C78 66.3748 76.3087 68 74.3125 68H7.6875C5.58553 68 4 66.429 4 64.2857V7.71429C4 5.67718 5.6395 4 7.6875 4ZM33.0294 34.8766C31.5444 35.7168 31.0216 37.6018 31.8618 39.0868C32.702 40.5718 34.5869 41.0945 36.0719 40.2543C37.3098 39.554 38.8816 40.0117 39.55 41.2671L39.7897 41.7174C40.561 43.1661 40.037 44.9655 38.6085 45.7737L28.5686 51.454C27.0836 52.2942 26.5609 54.1791 27.4011 55.6641C28.2412 57.1491 30.1262 57.6719 31.6112 56.8317L46.947 48.1551C48.432 47.3149 48.9548 45.43 48.1146 43.945C47.8179 43.4206 47.3909 43.0162 46.8972 42.7494C46.4692 42.5181 45.9897 42.3194 45.5047 42.1185C44.297 41.6182 43.0548 41.1036 42.4854 40.0341C41.7095 38.5767 42.2367 36.7665 43.6736 35.9535L53.4314 30.4328C54.9164 29.5926 55.4392 27.7077 54.599 26.2227C53.7588 24.7377 51.8739 24.2149 50.3889 25.0551L33.0294 34.8766Z" fill="url(#paint0_radial_775_196)"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M33.3125 0L43.5625 10.2857H74.3125C78.4766 10.2857 82 13.8214 82 18V64.2857C82 68.625 78.4766 72 74.3125 72H7.6875C3.36328 72 0 68.625 0 64.2857V7.71429C0 3.53571 3.36328 0 7.6875 0H33.3125ZM7.6875 4H31.6516L41.9016 14.2857H74.3125C76.2543 14.2857 78 16.0174 78 18V64.2857C78 66.3748 76.3087 68 74.3125 68H7.6875C5.58553 68 4 66.429 4 64.2857V7.71429C4 5.67718 5.6395 4 7.6875 4ZM33.0294 34.8766C31.5444 35.7168 31.0216 37.6018 31.8618 39.0868C32.702 40.5718 34.5869 41.0945 36.0719 40.2543C37.3098 39.554 38.8816 40.0117 39.55 41.2671L39.7897 41.7174C40.561 43.1661 40.037 44.9655 38.6085 45.7737L28.5686 51.454C27.0836 52.2942 26.5609 54.1791 27.4011 55.6641C28.2412 57.1491 30.1262 57.6719 31.6112 56.8317L46.947 48.1551C48.432 47.3149 48.9548 45.43 48.1146 43.945C47.8179 43.4206 47.3909 43.0162 46.8972 42.7494C46.4692 42.5181 45.9897 42.3194 45.5047 42.1185C44.297 41.6182 43.0548 41.1036 42.4854 40.0341C41.7095 38.5767 42.2367 36.7665 43.6736 35.9535L53.4314 30.4328C54.9164 29.5926 55.4392 27.7077 54.599 26.2227C53.7588 24.7377 51.8739 24.2149 50.3889 25.0551L33.0294 34.8766Z" fill="url(#paint1_radial_775_196)"/>
                                        </g>
                                        <defs>
                                        <radialGradient id="paint0_radial_775_196" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.0416 3.80935) rotate(56.1963) scale(82.0636 65.423)">
                                        <stop stopColor="#FFB1EE"/>
                                        <stop offset="0.260417" stopColor="#B77EFF"/>
                                        <stop offset="0.692708" stopColor="#5056DD"/>
                                        <stop offset="1" stopColor="#4F1CDF"/>
                                        </radialGradient>
                                        <radialGradient id="paint1_radial_775_196" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.0416 3.80935) rotate(56.1963) scale(82.0636 65.423)">
                                        <stop stopColor="#FFB1EE"/>
                                        <stop offset="0.260417" stopColor="#B77EFF"/>
                                        <stop offset="0.692708" stopColor="#5056DD"/>
                                        <stop offset="1" stopColor="#4F1CDF"/>
                                        </radialGradient>
                                        <clipPath id="clip0_775_196">
                                        <rect width="82" height="72" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                }>
                                <Button onClick={showAddModal} type="primary" size="large"><FolderAddOutlined/> Create One Now</Button>
                            </Empty> 
                        </Row>
                    }
                    {   researchList && researchList.map((research) => (
                        <Col xxl={4} xl={6} lg={8} md={12} xs={24} style={{ marginBottom: '16px' }} key={research.id}>
                            <motion.div
                                initial={{ scale: 0.4, opacity: 0 }}
                                layout
                                whileInView={{ scale: 1, opacity: 1 }} >
                                {researchCard(research)}
                            </motion.div>
                        </Col> ))
                    }
                </Row>
                <Modal
                    title="Add Research"
                    onCancel={handleAddModalCancel}
                    onOk={handleAddModalOK}
                    width={400}
                    okText="Create Research"
                    okButtonProps={{ loading: loading, disabled: formError }}
                    open={addModalOpen}
                    onPressEnter={handleAddModalOK}
                    afterOpenChange={focusTitleInput}>
                    <Space style={{ width: '100%' }} size="small" direction="vertical">
                        { /* 
                        <Row justify="center">

                        <Segmented
                            style={{ borderRadius: 8, marginTop: 12 }}
                            options={modelOptiopns}
                            onChange={(value) => researchModelChange(value)}
                            value={researchModel}
                        />
                        </Row>
                         */}
                        <p>Research name</p>
                        <Input
                            value={researchName}
                            ref={titleInputRef}
                            placeholder="Enter a research name"
                            onInput={(e) => researchNameFieldChange(e)}
                            />
                        <label style={{ userSelect: "none", cursor: "pointer" }}>
                            <Space size="small">
                            <Tooltip title="A description will help guide the AI to your specific requirements"> 
                                <InfoCircleFilled style={{ color: token.colorPrimary }} />
                            </Tooltip>
                            Research description 
                            <Switch 
                                checked={descriptionEnabled} 
                                title="Research Description" 
                                onChange={toggleDescription}/>
                            </Space>
                        </label>
                        { descriptionEnabled && 
                            <TextArea
                                value={researchDescription}
                                autoFocus
                                maxLength={200}
                                rows={3}
                                onInput={(e) => setResearchDescription(e.target.value)}
                                placeholder="Provide a brief description of the research. e.g. User interviews for Acme App regarding X feature"
                            /> }
                    </Space>
                </Modal>
                <PremiumCheckout modalOpen={premiumCheckoutModalOpen} modalCloseMethod={handlePremiumCheckoutClose} />
        </div>
    );
}