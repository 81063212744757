import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import './App.css';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { FolderOpenOutlined, DownloadOutlined , UserOutlined, StarFilled, CloudUploadOutlined, SettingOutlined, FileTextOutlined } from "@ant-design/icons";
import { Menu, Layout, ConfigProvider, theme, Alert, Modal } from "antd";

import { UserAuth } from './context/AuthContext';

import Cookies from "js-cookie";
import { Result, Button, Space, Row } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { Login } from './views';
import { Loader, PremiumCheckout, Footer as FooterComponent } from './components';
import { motion } from "framer-motion";

import logo from "./logo.png"
import logoBright from "./logo-bright.png"


const TRACKING_ID = "G-SB59KBNTJ2"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

const { Sider, Content } = Layout;

function App() {
  const navigate = useNavigate();
  const { user, userLoaded, userTheme, themeChange, userData, needsVerification, logOut } = UserAuth();
  const {darkAlgorithm, defaultAlgorithm, useToken} = theme;
  const {token} = useToken();

  const [loading, setLoading] = useState(true);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);  const [currentMenu, setCurrentMenu] = useState('/');
  const themeCookie = Cookies.get("userTheme") ? Cookies.get("userTheme") : "";

  let location = useLocation();

  const handleCheckoutModalOpen = () => {
    setCheckoutModalOpen(true);
  }
  const handleCheckoutModalClose = () => {
    setCheckoutModalOpen(false);
  }
  const handleFeedbackModalOpen = () => {
    setFeedbackModalOpen(true);
  }
  const handleFeedbackModalClose = () => {
    setFeedbackModalOpen(false);
  }
  const upgradeTheme = userTheme && userTheme === "dark" ? "upgrade-button dark" : "upgrade-button"
  const menuItems = [
    userData && userData.subscriptionStatus === "Trial" &&
    {
      label: "Upgrade Now",
      key: '/premium',
      className: upgradeTheme,
      icon: <CloudUploadOutlined />,
    },
    {
      label: 'Researches',
      key: '/',
      icon: <FolderOpenOutlined />,
    },
    /*
    {
      label: 'Billing',
      key: '/billing',
      icon: <MoneyCollectOutlined />,
    },*/
    {
      label: 'Resources',
      key: '/resources',
      icon: <DownloadOutlined />,
      
    },
    {
      label: 'Settings',
      key: '/settings',
      icon: <SettingOutlined />,
    },
    {
      label: 'Account',
      key: '/account',
      icon: <UserOutlined />,
    }, 
    /*
    {
      label: 'Transcripts',
      key: '/transcripts',
      icon: <FileTextOutlined />,
    },
    */
    {
      label: 'Feedback',
      key: '/feedback',
      icon: <StarFilled style={{color: token.orange}} />,
    },
  ];
  
  const handleMenuClick = ({ key }) => {
    if(key) {
      
      if(key === "/premium"){
        handleCheckoutModalOpen();
      } else if(key === "/feedback"){
        handleFeedbackModalOpen();
      } else {
        navigate(key)
        setCurrentMenu(key);
      }
    }
  }
  const setNavToPath = (route) =>{
    setCurrentMenu(route);
  };
 
  useEffect(() => {
    const destination = location.pathname;
    setNavToPath(destination);
  }, [location.pathname]); // Add location.pathname as a dependency
  useEffect(() => {
    if(userLoaded){
      setLoading(false);
      console.log(needsVerification)
    }
  }, [userLoaded])

  useEffect(() => {
    if(themeCookie){
      themeChange(themeCookie);
    } else {
      themeChange('default')
    }
  }, [themeCookie])
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        algorithm: userTheme === "dark" ? darkAlgorithm : defaultAlgorithm,
        token:
          userTheme === "dark"
            ? {
                colorPrimary: "#8D5AF7",
                borderRadius: 24,
                paddingLG: 16,
                colorPrimaryBg: "rgba(141, 90, 247, 0.2)",
                colorSuccess: "#27AE60",
                colorBorderSecondary: "rgba(255,255,255, 0.1)",
                colorInfo: "#8D5AF7",
              }
            : {
                colorPrimary: "#5E28D1",
                borderRadius: 24,
                paddingLG: 16,
                colorPrimaryBg: "#EAE0FF",
                colorSuccess: "#27AE60",
                colorBorderSecondary: "#EAE0FF",
                colorInfo: "#5E28D1",
              },
        components: {
          Tabs: {
            colorBorderSecondary: "none",
          },
          Spin: {
            colorBgMask: "#FFFFFF",
          },
          Upload: {
            borderRadius: 8,
          },
        },
      }}
    >
      {loading ? (
        <Loader title="Loading" loading={loading} fullscreen />
      ) : user && user.uid ? (
        needsVerification === true ? (
          <Row justify="center" align="middle" style={{ height: "100vh", backgroundColor: token.colorBgLayout }}>
            <Result
              title="Email Verification Required"
              subTitle={
                <Space direction="vertical" size="middle">
                  <span>
                    Please verify your email before logging in, at&nbsp;
                    <b>{user && user.email}</b>
                  </span>
                  <Button type="link" onClick={logOut}>
                    Back to login
                  </Button>
                </Space>
              }
              icon={<WarningFilled style={{ color: "#FA9933" }} />}
            />
          </Row>
        ) : (
        <>
          {userData &&
            userData.subscriptionStatus === "Trial" &&
            (userData.remainingTrial > 0 ? (
              <Alert
                type="info"
                className="trialAlert"
                description={`You have ${userData.remainingTrial} days of trial left`}
                banner
              />
            ) : (
              <Alert
                className="trialAlert"
                description="Your trial has expired, upgrade now to keep using Research Studio"
                type="warning"
                banner
              />
            ))}
          <Layout className="App" style={{ width: "100%", height: "100%" }}>
            <Sider width={220}>
              <motion.div
                layout
                initial={{ x: -240, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <Link to="/" className="logo">
                  {userTheme === "dark" ? (
                    <img alt="Research studio logo" src={logoBright} />
                  ) : (
                    <img alt="Research studio logo" src={logo} />
                  )}
                </Link>
                <Menu
                  mode="inline"
                  style={{
                    border: "none",
                    backgroundColor:
                      userTheme === "dark" ? "#242424" : "#ffffff",
                    boxShadow: token.boxShadow,
                  }}
                  items={menuItems}
                  selectedKeys={[currentMenu]}
                  onClick={handleMenuClick}
                />
              </motion.div>
              <Modal
                footer={null}
                width={420}
                open={feedbackModalOpen}
                onCancel={handleFeedbackModalClose}
              >
                <iframe
                  className="feedbackForm"
                  width="100%"
                  height="650px"
                  src="https://tally.so/r/nrP4kv"
                  title="Feedback form"
                />
              </Modal>
              <PremiumCheckout
                modalOpen={checkoutModalOpen}
                modalCloseMethod={handleCheckoutModalClose}
              />
            </Sider>
            <Content className="mainContent">
              <Outlet
                className="animatedContainer contentContainer"
                context={UserAuth}
              />
              <FooterComponent />
            </Content>
          </Layout>
        </> )
      ) : <Login /> }
    </ConfigProvider>
  );
}

export default App;
